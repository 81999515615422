import { ActionReducerMap } from "@ngrx/store";
import { AuthenticationState, authenticationReducer } from "./Authentication/authentication.reducer";
import { communityReducer } from "./Event/event-reducer";
import { menuReducer } from "./Event/menus.reducer";
import { eventReducer } from "./Event/store-event.reducer";
import { UserReducer, UserState } from "./User/user-reducer";
import { LayoutState, layoutReducer } from "./layout/layout-reducers";


export interface RootReducerState {
    layout: LayoutState,
    authentication: AuthenticationState;
    User: UserState,
    community: any,
    event: any,
    menu: any
}

export const rootReducer: ActionReducerMap<RootReducerState> = {
    layout: layoutReducer,
    authentication: authenticationReducer,
    User: UserReducer,
    community: communityReducer,
    event: eventReducer,
    menu: menuReducer
}
