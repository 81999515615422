import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { showAlertMessage, trimSpacesFromCenter } from '@app/core/helpers/utils';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { LeagueService } from '@app/core/services/league.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-make-league-payment',
  standalone: true,
  imports: [PipesModule, CommonModule],
  templateUrl: './make-league-payment.component.html',
  styleUrl: './make-league-payment.component.scss'
})
export class MakeLeaguePaymentComponent {

  @Input() leaguePaymentData: any = {}
  @Output() goToStep: any = new EventEmitter<any>()
  @Output() setStepData: EventEmitter<any> = new EventEmitter<any>();

  constructor(private leagueService: LeagueService,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {
  }

  teamPaymentIntent() {
    const payload = {
      "teamId": this.leaguePaymentData?.teamId,
      "leagueId": this.leaguePaymentData?.leagueId,
      "teamFee": this.leaguePaymentData?.teamFee,
      "totalAmountPerRoster": this.leaguePaymentData?.totalAmountPerRoster,
      "totalRoster": this.leaguePaymentData?.totalRoster,
      "serviceFee": this.leaguePaymentData?.serviceFee,
      "ageGroup": this.leaguePaymentData?.ageGroup,
      "divisionId": this.leaguePaymentData?.divisionId,
      "totalCost": this.leaguePaymentData?.totalCost,
      "email": this.leaguePaymentData?.email,
      "firstName": trimSpacesFromCenter(this.leaguePaymentData?.firstName),
      "lastName": trimSpacesFromCenter(this.leaguePaymentData?.lastName),
      "athlete": this.leaguePaymentData?.athlete
    }
    this.ngxSpinnerService.show();
    this.leagueService.leaguePaymentIntent(payload).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        this.localStorageService.saveData('leaguePaymentData', response?.data)
        this.goToStep.emit(2)
      } else {
        showAlertMessage(response?.message ?? "Something went wrong!", "error");
      }
    }, (error) => {
      this.ngxSpinnerService.hide();
    });

  }

}
