import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { showAlertMessage } from '@app/core/helpers/utils';
import { AuthenticationService } from '@app/core/services/auth.service';
import { UserdataService } from '@app/core/services/userdata.service';
import { Store } from '@ngrx/store';
import { NgOtpInputModule } from 'ng-otp-input';

@Component({
  selector: 'app-sign-up-step2',
  standalone: true,
  imports: [NgOtpInputModule, CommonModule],
  templateUrl: './sign-up-step2.component.html',
  styleUrls: ['./sign-up-step2.component.css']
})
export class SignUpStep2Component implements OnInit {

  @Output() stepValue = new EventEmitter<any>();
  otp: string = "";
  isOtpCorrect: boolean = false;
  submitted: boolean = false;
  isResetDisabled: boolean = true;
  countdown: number = 30;
  isCountdownStart: boolean = true
  // @Input() otpValueOnNext: any

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
  };
  email: any;
  password: any;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private store: Store,
    private userdataService: UserdataService
  ) {
    this.email = localStorage.getItem('Email')
    this.password = localStorage.getItem('password')
  }

  ngOnInit() {
    if (this.isCountdownStart) {
      this.startCountdown();
    }
  }

  onOtpChange(otp: string) {
    this.otp = otp;
    if (otp.length === 6) {
      this.isOtpCorrect = true;
    } else {
      this.isOtpCorrect = false;
    }
  }

  emitStep(step: any) {
    this.stepValue.emit(step);
  }

  navigateToLogin(event: any) {
    event.preventDefault();
    this.submitted = true;

    let otpDetail = {
      email: this.email,
      otp: this.otp
    }
    this.onEnterOtp(otpDetail)
  }
  onEnterOtp(userData: any) {
    this.authService.enterOtp(userData).subscribe(
      (response: any) => {
        if (response.data) {
          this.submitted = false;
          let userData = response?.data ?? {};
          localStorage.setItem("token", userData?.token ?? "");
          localStorage.setItem("organizations", userData?.organizations ?? "");
          localStorage.setItem('currentUser', JSON.stringify(userData));
          localStorage.setItem('currentUserDetails', JSON.stringify(userData));
          this.userdataService.setUserData(userData);
          showAlertMessage(response.message ?? "Welcome to sketchplay", "success", () => this.router.navigate(['/dashboard']));

        }
      }, (response) => {
        showAlertMessage(response, 'error', () => {
          this.submitted = false;
        })
      })
  }

  resetOtp(): void {
    let email = {
      email: this.email
    }
    this.authService.resendOtp(email).subscribe(response => {
      if (response.status) {
        showAlertMessage(response?.message, "success");
        this.countdown = 30;
        this.startCountdown();
      }
    }, (response) => {
      this.isCountdownStart = false;
      // this.submitted = false;
      showAlertMessage(response ?? 'Something went wrong', "error");
    })
  }

  startCountdown(): void {
    this.isResetDisabled = true; // Disable the button initially
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(interval);
        this.isResetDisabled = false; // Enable the button after one minute
        // this.countdown = 60; // Reset the countdown
      }
    }, 1000);
  }

}
